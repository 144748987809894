import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import * as image from "../data/constants/images";
import * as e from "../data/enums";
// import * as c from "../data/constants/constants";
import { LOGO } from "../data/constants/images";
import { useLocation } from "react-router-dom";

export default function Home(): JSX.Element {
	const { t, i18n } = useTranslation("Home");
	const [animateHero, setAnimateHero] = useState(false);
	const [animateButtonWatchDemo, setAnimateButtonWatchDemo] = useState(false);
	const [animateButtonTryForFree, setAnimateButtonTryForFree] = useState(false);
	const [animateFeatures, setAnimateFeatures] = useState(false);
	const [animationClass, setAnimationClass] = useState("");
	const location = useLocation();
	const canonicalUrl = `https://auriseartraining.com${location.pathname}`;

	useEffect(() => {
		const animationsPlayedSchools = localStorage.getItem("animationsPlayedHome");
		if (animationsPlayedSchools) return;
		setAnimationClass("hide");
		setAnimateHero(true);
		setAnimateButtonWatchDemo(true);
		setAnimateButtonTryForFree(true);
		setAnimateFeatures(true);

		localStorage.setItem("animationsPlayedHome", "true");
	}, []);

	const animationClass1 = animateHero ? "animate-fade-in" : animationClass;
	const animationClass2 = animateButtonWatchDemo ? "animate-fade-in" : animationClass;
	const animationClass3 = animateButtonTryForFree ? "animate-fade-in" : animationClass;
	const animationClass4 = animateFeatures ? "animate-fade-in" : animationClass;

	return (
		<main className="home">
			<Helmet>
				<title>{t("pageTitle")}</title>
				<link rel="canonical" href={canonicalUrl} />
				<meta property="og:title" content={t("pageTitle")} />
				<meta property="og:description" content={t("pageDescription")} />
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:type" content="website" />
				<meta http-equiv="Content-Language" content={i18n.language} />
			</Helmet>

			<section className={`hero ${animationClass1}`}>
				<img id="logo" alt="logo Auris Ear Training" src={LOGO} />
				<div className="heroTextAndLogo">
					<h1>Auris Ear Training</h1>
					<p className="long">{t("heroTextLong")}</p>
					<p className="short">{t("heroTextShort")}</p>
				</div>
			</section>

			<div className="button-container">
				<a
					href="https://student.auriseartraining.com/login"
					className={`button button-secondary ${animationClass2}`}
					rel="nofollow"
				>
					{t("login")}
				</a>
				<a href="https://student.auriseartraining.com" className={`button signUp ${animationClass3}`}>
					{t("tryWithoutLogin")}
				</a>

				{/* <button
					className={`button-stores ${animationClass3}`}
					onClick={() => (window.location.href = 'https://play.google.com/store/apps/details?id=com.auris')}
					aria-label={t("downloadPlayStore")}
				>
					<img className="storeImg" src={image.ICONS[e.Icons.PLAY_STORE]} alt="Play Store" />
				</button> */}
				<a
					href="https://play.google.com/store/apps/details?id=com.auris"
					target="_blank"
					rel="noopener noreferrer"
					className={`button-stores ${animationClass3}`}
					aria-label={t("downloadPlayStore")}
				>
					<img className="storeImg" src={image.ICONS[e.Icons.PLAY_STORE]} alt="Play Store" />
				</a>
				{/* <button
					className={`button-stores ${animationClass3}`}
					onClick={() =>
						(window.location.href = "https://apps.apple.com/app/auris-ear-training/id6476059416")
					}
					aria-label={t("downloadAppStore")}
				>
					<img className="storeImg" src={image.ICONS[e.Icons.APP_STORE]} alt="App Store" />
				</button> */}
				<a
					href="https://apps.apple.com/app/auris-ear-training/id6476059416"
					target="_blank"
					rel="noopener noreferrer"
					className={`button-stores ${animationClass3}`}
					aria-label={t("downloadAppStore")}
				>
					<img className="storeImg" src={image.ICONS[e.Icons.APP_STORE]} alt="App Store" />
				</a>
			</div>

			<section className={`features ${animationClass4}`}>
				<div className="feature">
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.HOME]}
						alt="screenshot of student portal home page"
					/>
					<div className="feature-card">
						<h2>{t("features.trainYourEars")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.INTERVALS]} alt="Intervals" />
							{t("modules.intervals")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.SCALES]} alt="Scales" />
							{t("modules.scales")}{" "}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.MELODIC_DICTATION]} alt="Melodic Dictation" />
							{t("modules.melodicDictation")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.CHORDS]} alt="Chords" />
							{t("modules.chords")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.INVERSIONS]} alt="Inversions" />
							{t("modules.inversions")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.PROGRESSIONS]} alt="Chord Progressions" />
							{t("modules.progressions")}
						</div>
					</div>
				</div>

				<div className="feature">
					<div className="feature-card">
						<h2>{t("features.funExercises")}</h2>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.DIFFICULTY]}
								alt="Suitable for both beginners and advanced musicians"
							/>
							{t("features.beginnersAndAdvanced")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.LEARNING_CURVE]} alt="Gradual difficulty curve" />
							{t("features.gradualDifficulty")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.KNOWLEDGE]} alt="No prior knowledge required" />
							{t("features.noPriorKnowledge")}
						</div>
					</div>
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.GAMEPLAY]}
						alt="screenshot of gameplay"
					/>
				</div>

				<div className="feature">
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.STATS]}
						alt="screenshot of stats page"
					/>
					<div className="feature-card">
						<h2>{t("features.trackProgress")}</h2>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.STREAK]}
								alt="Practice daily to grow your streak and develop a learning habit"
							/>
							{t("features.practiceDaily")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.CUP]}
								alt="Try to beat your high score and stay on top of the leaderboard"
							/>
							{t("features.beatHighScore")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.STATISTICS]}
								alt="Keep track of your progress with detailed statistics"
							/>
							{t("features.detailedStats")}
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
