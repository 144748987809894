import React from "react";
import { useTranslation } from "react-i18next";
import useDeviceIsMobile from "../hooks/useDeviceIsMobile";

export default function Footer(): JSX.Element {
	const { t } = useTranslation("Footer");

	const isMobile = useDeviceIsMobile();
	const currentYear = new Date().getFullYear();
	return (
		<footer>
			<nav>
				<a href="/legal">{t("footer.legal")}</a>
				{/* <a href="/aboutus">About Us</a> */}
				<a href="/contact">{t("footer.contact")}</a>
			</nav>
			<div className="rights">
				&copy; {currentYear} Auris Ear Training. {isMobile && <br />}
				{t("footer.patent")}
			</div>
		</footer>
	);
}
