import React from "react";
import { useTranslation } from "react-i18next";

export default function Testimonials(): JSX.Element {
	const { t } = useTranslation("Contact");

	return (
		<main>
			<div className="card">
				<div>
					<h1>{t("contact.title")}</h1>
					<h2>{t("contact.description")}</h2>
					<span>info@auriseartraining.com</span>

					<br />
					<br />
					<br />

					<a href="/" className="home-link">
						{t("Header:header.home")}
					</a>

					<br />
				</div>
			</div>
		</main>
	);
}
