import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./styles/style.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Contact from "./pages/Contact";
import Pricing from "./pages/Pricing";
import Legal from "./pages/legal/Legal";
import CookiePolicy from "./pages/legal/CookiePolicy";
import TermsOfService from "./pages/legal/TermsOfService";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import Schools from "./pages/Schools";
import Support from "./pages/Support";
import FAQ from "./pages/FAQ";
import ErrorPage from "./pages/ErrorPage";
import Footer from "./components/Footer";
import ErrorBoundary from "./components/ErrorBoundary";
// import AboutUs from "./pages/AboutUs";
// import Testimonials from "./pages/Testimonials";

export default function App() {
	useEffect(() => {
		// Function to clear specific localStorage items
		const clearAnimationFlags = () => {
			localStorage.removeItem("animationsPlayedHome");
			localStorage.removeItem("animationsPlayedSchools");
		};

		// Add the event listener for beforeunload event
		window.addEventListener("beforeunload", clearAnimationFlags);

		// Cleanup the event listener when the component unmounts
		return () => {
			window.removeEventListener("beforeunload", clearAnimationFlags);
		};
	}, []);

	return (
		<ErrorBoundary>
			<Router>
				<div className="App">
					<div className="left"></div>
					<div className="center">
						<Header />
						<AvailableRoutes />
						<Footer />
					</div>
					<div className="right"></div>
				</div>
			</Router>
		</ErrorBoundary>
	);
}

function AvailableRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/home/" element={<Navigate to="/" replace />} />
			<Route path="/schools/" element={<Schools />} />
			<Route path="/faq/" element={<FAQ />} />
			{/* <Route path="/aboutus" element={<AboutUs />} /> Page is potentially being seen as Orphan! */}
			<Route path="/pricing/" element={<Pricing />} />
			<Route path="/support/" element={<Support />} />
			<Route path="/contact/" element={<Contact />} />
			<Route path="/legal/" element={<Legal />} />
			<Route path="/cookiepolicy/" element={<CookiePolicy />} />
			<Route path="/privacypolicy/" element={<PrivacyPolicy />} />
			<Route path="/termsofservice/" element={<TermsOfService />} />
			{/* <Route path="/testimonials" element={<Testimonials />} /> Page is potentially being seen as Orphan! */}
			{/* <Route path="/404" element={<ErrorPage message="Page not found" />} /> */}
			<Route path="/*" element={<ErrorPage message="Page not found" />} />
		</Routes>
	);
}
