import React from "react";
import { useTranslation } from "react-i18next";
import AdBanner from "../components/AdBanner";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function FAQ(): JSX.Element {
	const { t } = useTranslation("FAQ");
	const location = useLocation();
	const canonicalUrl = `https://auriseartraining.com${location.pathname}/`;

	return (
		<main>
			<Helmet>
				<title>{t("faq.pageTitle")}</title>
				<link rel="canonical" href={canonicalUrl} />
				<meta name="description" content={t("faq.pageDescription")} />
				<meta property="og:title" content={t("faq.pageTitle")} />
				<meta property="og:description" content={t("faq.pageDescription")} />
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:type" content="website" />
			</Helmet>

			<AdBanner />

			<div className="faq-section">
				<h2>{t("faq.gameplay.title")}</h2>
				<FAQItem
					question={t("faq.gameplay.soundIssue.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.gameplay.soundIssue.answer1")}</p>
							<p>{t("faq.gameplay.soundIssue.answer2")}</p>
							<p>{t("faq.gameplay.soundIssue.answer3")}</p>
						</div>
					}
				/>
				<FAQItem
					question={t("faq.gameplay.practiceTime.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.gameplay.practiceTime.answer1")}</p>
							{/* <p>{t("faq.gameplay.practiceTime.answer2")}</p> */}
							<p>{t("faq.gameplay.practiceTime.answer3")}</p>
						</div>
					}
				/>
				{/* <FAQItem
					question={t("faq.gameplay.ranking.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.gameplay.ranking.answer")}</p>
						</div>
					}
				/> */}
			</div>
			<div className="faq-section">
				<h2>{t("faq.studentEngagement.title")}</h2>
				<FAQItem
					question={t("faq.studentEngagement.gamification.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.studentEngagement.gamification.answer1")}</p>
							<p>{t("faq.studentEngagement.gamification.answer2")}</p>
							<p>{t("faq.studentEngagement.gamification.answer3")}</p>
							<p>{t("faq.studentEngagement.gamification.answer4")}</p>
						</div>
					}
				/>
				<FAQItem
					question={t("faq.studentEngagement.accessibility.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.studentEngagement.accessibility.answer1")}</p>
							<p>{t("faq.studentEngagement.accessibility.answer2")}</p>
							<p>{t("faq.studentEngagement.accessibility.answer3")}</p>
							<p>{t("faq.studentEngagement.accessibility.answer4")}</p>
							<p>{t("faq.studentEngagement.accessibility.answer5")}</p>
						</div>
					}
				/>
				<FAQItem
					question={t("faq.studentEngagement.deviceSupport.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.studentEngagement.deviceSupport.answer")}</p>
						</div>
					}
				/>
			</div>
			<div className="faq-section">
				<h2>{t("faq.educatorInsights.title")}</h2>
				<FAQItem
					question={t("faq.educatorInsights.insights.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.educatorInsights.insights.answer1")}</p>
							<p>{t("faq.educatorInsights.insights.answer2")}</p>
							<p>{t("faq.educatorInsights.insights.answer3")}</p>
							{/* <p>{t("faq.educatorInsights.insights.answer4")}</p> */}
						</div>
					}
				/>
				<FAQItem
					question={t("faq.educatorInsights.customization.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.educatorInsights.customization.answer")}</p>
						</div>
					}
				/>
			</div>
			<div className="faq-section">
				<h2>{t("faq.easeOfUse.title")}</h2>
				<FAQItem
					question={t("faq.easeOfUse.integration.question")}
					answer={
						<div className="faq-answer">
							{/* <p>{t("faq.easeOfUse.integration.answer1")}</p> */}
							<p>{t("faq.easeOfUse.integration.answer2")}</p>
						</div>
					}
				/>
				<FAQItem
					question={t("faq.easeOfUse.support.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.easeOfUse.support.answer")}</p>
						</div>
					}
				/>
			</div>
			<div className="faq-section">
				<h2>{t("faq.scalability.title")}</h2>
				<FAQItem
					question={t("faq.scalability.classSize.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.scalability.classSize.answer")}</p>
						</div>
					}
				/>
				<FAQItem
					question={t("faq.scalability.features.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.scalability.features.answer")}</p>
						</div>
					}
				/>
			</div>
			<div className="faq-section">
				<h2>{t("faq.dataSecurity.title")}</h2>
				<FAQItem
					question={t("faq.dataSecurity.security.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.dataSecurity.security.answer")}</p>
						</div>
					}
				/>
				<FAQItem
					question={t("faq.dataSecurity.storage.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.dataSecurity.storage.answer")}</p>
						</div>
					}
				/>
			</div>
			<div className="faq-section">
				<h2>{t("faq.paidPlans.title")}</h2>
				<FAQItem
					question={t("faq.paidPlans.manage.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.paidPlans.manage.answer1")}</p>
							<p>{t("faq.paidPlans.manage.answer2")}</p>
						</div>
					}
				/>
				<FAQItem
					question={t("faq.paidPlans.switch.question")}
					answer={
						<div className="faq-answer">
							<p>{t("faq.paidPlans.switch.answer1")}</p>
							<p>{t("faq.paidPlans.switch.answer2")}</p>
						</div>
					}
				/>
			</div>
		</main>
	);
}

function FAQItem(props: { question: string; answer: any }): JSX.Element {
	return (
		<div className="faq-item">
			<details>
				<summary>{props.question}</summary>
				{props.answer}
			</details>
		</div>
	);
}
