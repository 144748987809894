import React from "react";
import { Helmet } from "react-helmet";
import { CookiePolicyContent } from "./CookiePolicy";
import { PrivacyPolicyContent } from "./PrivacyPolicy";
import { TermsOfServiceContent } from "./TermsOfService";

export default function Legal(): JSX.Element {
	return (
		<>
			<Helmet>
				<title>Cookie Policy for Auris Ear Training</title>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>

			<main className="legal">
				<section className="card">
					<div className="card-text">
						<details>
							<summary>
								<h2>Cookie Policy for Auris Ear Training</h2>
							</summary>
							<CookiePolicyContent />
						</details>
					</div>
				</section>

				<section className="card">
					<div className="card-text">
						<details>
							<summary>
								<h2>Privacy Policy for Auris Ear Training</h2>
							</summary>
							<PrivacyPolicyContent />
						</details>
					</div>
				</section>

				<section className="card">
					<div className="card-text">
						<details>
							<summary>
								<h2>Terms of Service for Auris Ear Training</h2>
							</summary>
							<TermsOfServiceContent />
						</details>
					</div>
				</section>
			</main>
		</>
	);
}
