import React from "react";
import { useNavigate } from "react-router-dom";
import * as c from "../data/constants/constants";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Pricing(): JSX.Element {
	const { t } = useTranslation("Pricing");
	const navigate = useNavigate();
	const location = useLocation();
	const canonicalUrl = `https://auriseartraining.com${location.pathname}/`;

	return (
		<main>
			<Helmet>
				<title>{t("pricing.pageTitle")}</title>
				<link rel="canonical" href={canonicalUrl} />
				<meta name="description" content={t("pricing.pageDescription")} />
				<meta property="og:title" content={t("pricing.pageTitle")} />
				<meta property="og:description" content={t("pricing.pageDescription")} />
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:type" content="website" />
			</Helmet>

			<div className="overviewTable ">
				<table>
					<thead className="theadCSS theadCSSRounded">
						<tr>
							<th>{t("pricing.title.plan")}</th>
							<th>{t("pricing.title.noAccount")}</th>
							<th>{t("pricing.title.free")}</th>
							<th>{t("pricing.title.monthly")}</th>
							<th>{t("pricing.title.yearly")}</th>
							<th>{t("pricing.title.schools")}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<b>{t("pricing.question.price")}</b>
							</td>
							<td>
								<b>{t("pricing.answer.free")}</b>
							</td>
							<td>
								<b>{t("pricing.answer.free")}</b>
							</td>
							<td>
								<b>€{c.montlyPrice}</b>
								<sup>{t("pricing.answer.month")}</sup>
							</td>
							<td>
								<b>€{c.yearlyPrice}</b>
								<sup>{t("pricing.answer.month")}</sup>
							</td>
							<td className="link" onClick={() => navigate("/Contact")}>
								<b>{t("pricing.answer.contact")}</b>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.saveProgress")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.access")}</td>
							<td>{t("pricing.answer.limited")}</td>
							<td>{t("pricing.answer.limited")}</td>
							<td className="green">
								<p>{t("pricing.answer.fullAccess")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.fullAccess")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.fullAccess")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.ads")}</td>
							<td>{t("pricing.answer.perhapsInFuture")}</td>
							<td>{t("pricing.answer.perhapsInFuture")}</td>
							<td className="green">
								<p>{t("pricing.answer.never")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.never")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.never")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.ranking")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.top3")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.darkMode")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.practiceMode")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.sounds")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.progress")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.targets")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.exams")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
						<tr>
							<td>{t("pricing.question.studentInfo")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td>{t("pricing.answer.no")}</td>
							<td className="green">
								<p>{t("pricing.answer.yes")}</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</main>
	);
}
